import React from "react"
import { ApolloProvider } from "@apollo/client"

import { AppProvider } from "context/app_context"

import { client } from "./client"

//  Root Element which is a wrapper to the app.
export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </AppProvider>
)
